#content-filter-bar {
  flex-wrap: wrap;
  justify-content: flex-end;
}

.filter {
  margin: 4px;
}
#date-filter {
  /* Input */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 150px;
  /* width: 176px; */
  height: 32px;
  background: #272727;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  flex: none;
  order: 4;
  flex-grow: 0;
  color: #fff;
}

#date-filter:focus-visible {
  outline: none;
}
#date-filter .react-datepicker__portal {
  position: fixed;
  z-index: 20; /* Increase the z-index value as necessary */
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
}
#month-picker {
  /* Input */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 150px;
  /* width: 176px; */
  height: 32px;
  background: #272727;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  flex: none;
  order: 4;
  flex-grow: 0;
  color: #fff;
}
#month-picker:focus-visible {
  outline: none;
}
#month-picker .react-datepicker__portal {
  position: fixed;
  z-index: 20; /* Increase the z-index value as necessary */
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
}
#year-picker {
  /* Input */
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  gap: 8px;
  width: 150px;
  height: 32px;
  background: #272727;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  flex: none;
  order: 4;
  flex-grow: 0;
  color: #fff;
}
#year-picker:focus-visible {
  outline: none;
}
#year-picker .react-datepicker__portal {
  position: fixed;
  z-index: 20; /* Increase the z-index value as necessary */
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%);
}
.page-view-icon {
  margin-right: 8px;
}

.checkbox-label {
  padding: 0px;
  font-size: 12px;
  color: #efefef;
}
#applyFilterBtn {
  color: #fff;
  border-radius: 24px;
  background: #0054ac;
  text-transform: none;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
}
#clearAllBtn {
  color: #3387df;
  border-radius: 24px;
  border: 1px solid #3387df;
  background: transparent;
  text-transform: none;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
}
.ytdText {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  color: #d0d0d0;
  padding-top: 30px;
}
.switchContainer {
  padding-top: 30px;
  margin-left: 7px !important;
}
.paddingTop12 {
  padding-top: 12px;
  padding-left: 10px;
}
.selectLabel {
  color: #d0d0d0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.light {
  color: #131313  !important;
}
.dark {
  color: #d0d0d0 !important;
}
.light-background {
  background: #FBFCFE !important;
}
.dark-background {
  background: #1d1d1d !important;
}
.disabledFilter {
  border-color: rgba(118, 118, 118, 0.3) !important;
  color: darkgray !important;
}

.drawer-content {
  display: flex;
  flex-direction: column;
  height: 75vh;
  justify-content: space-between;
  z-index: 999;
  background: #1d1d1d;
}
.drawer-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
}
.filter-title {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: #efefef;
}
.date-accordion-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.accordionDefaultStyle {
  /* background: #1d1d1d; */
  color: #d0d0d0;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* border-bottom: 1.5px solid #666666; */
}
